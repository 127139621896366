.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@-webkit-keyframes color-change-2x {
  0% {
    background: white;
  }
  100% {
    background: var(--pink);
  }
}
@keyframes color-change-2x {
  0% {
    background: white;
  }
  100% {
    background: var(--pink);
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    backwards;
  animation: slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) backwards;
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-in-blurred-left-1 {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    0.5s backwards;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.5s
    backwards;
}

.slide-in-blurred-left-2 {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    0.7s backwards;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.7s
    backwards;
}
.slide-in-blurred-left-3 {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    0.9s backwards;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.9s
    backwards;
}
.slide-in-blurred-left-4 {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    1.1s backwards;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 1.1s
    backwards;
}
.slide-in-blurred-left-5 {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    1.3s backwards;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 1.3s
    backwards;
}
.slide-in-blurred-left-6 {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    1.5s backwards;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 1.5s
    backwards;
}

.color-change-2x {
  -webkit-animation: color-change-2x 2s linear alternate both;
  animation: color-change-2x 2s linear alternate both;
}

.homePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.break {
  height: 25vh;
}

.projectsPage {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.introContainer {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.specialtiesContainer {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  margin-top: 12%;
}

.specialtiesHeaderText {
  font-size: 14px;
  color: var(--mediumGray);
  font-weight: 800;
}

.specialtiesTextContainer {
  display: flex;
  flex-direction: row;
}

.specialtiesText {
  font-size: 14px;
  color: var(--darkGray);
  font-weight: 800;
  margin: 0px;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: solid;
  border-bottom-color: var(--lightGray);
  width: 250px;
  text-align: left;
}

.bulletText {
  font-size: 20px;
  color: var(--darkGray);
  font-weight: 800;
  margin: 0px;
  border-bottom: solid;
  border-bottom-color: var(--lightGray);
  text-align: right;
  padding-top: 6px;
}

.graySquare {
  width: 50%;
  background-color: var(--mediumGray);
  height: 160%;
  position: absolute;
  top: 0px;
  z-index: -1;
  right: 0px;
  border-left: solid 25px var(--darkGray);
  box-sizing: border-box;
}

.pinkCircle {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: var(--pink);
  position: absolute;
  z-index: -2;
  left: 42%;
  top: 17%;
}

.introText {
  font-size: 7vw;
  font-weight: 800;
  margin: 0px;
  color: var(--darkGray);
}

.labelText {
  font-size: 24px;
  font-weight: 800;
  margin: 0px;
  color: var(--darkGray);
}

.projectsHeader {
  font-size: 120px;
  font-weight: 800;
  margin: 0px;
  color: var(--darkGray);
}

.projectsPink {
  font-size: 120px;
  font-weight: 800;
  margin: 0px;
  color: var(--pink);
}

.projectsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;
}

.projectButton {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectButtonActive {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px;
}

.projectButton:hover {
  transition: all 0.1s ease-in-out;
  transform: scale(1.1);
  cursor: pointer;
  /* border: solid 3px black; */
  box-sizing: border-box;
  background-color: #61dafb;
}

.projectButtonActive:hover {
  transition: all 0.1s ease-in-out;
  transform: scale(1.1);
  cursor: pointer;
  /* border: solid 3px black; */
  box-sizing: border-box;
  background-color: #61dafb;
}

.projectTitle {
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
  color: var(--darkGray);
}

.projectTitleActive {
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  color: var(--darkGray);
}

.projectLabel {
  font-size: 10px;
  font-weight: 700;
  margin: 0px;
  margin-top: 3px;
  color: var(--darkGray);
}

.activeProjectsHeader {
  font-size: 40px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 8%;
  color: var(--darkGray);
  margin-left: 5px;
}

.activeProjectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.verticalButtonContainer {
  display: flex;
  flex-direction: column;
}

.descriptionHeaderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  border-bottom: solid 2px var(--lightGray);
  padding-bottom: 10px;
}

.descriptionName {
  font-weight: 700;
  text-align: left;
  font-size: 28px;
  margin: 0;
}

.descriptionWebsite {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--mediumGray);
  text-align: right;
  width: 100%;
  margin: 0;
}
.descriptionWebsitePink {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--pink);
  text-align: right;
  width: 100%;
  margin: 0;
}
.descriptionWebsitePink:hover {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--mediumGray);
  text-align: right;
  width: 100%;
  margin: 0;
}

a {
  text-decoration: none;
}

.descriptionWebsite:hover {
  color: var(--pink);
}

.descriptionText {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--darkGray);
  text-align: left;
  margin: 0;
  padding-top: 10px;
}

.stackText {
  font-weight: 700;
  font-size: 14px;
  color: var(--mediumGray);
  text-align: left;
  margin: 0;
  padding-top: 10px;
  text-transform: uppercase;
  border-bottom: solid 2px var(--lightGray);
  padding-bottom: 20px;
  width: 100%;
}

.stackContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
}

.stackListText {
  font-weight: 700;
  font-size: 12px;
  color: var(--darkGray);
  text-align: left;
  margin: 0;
  padding-top: 10px;
  text-transform: uppercase;
  border-bottom: solid 2px var(--lightGray);
  padding-bottom: 5px;
  width: 100%;
}

.descriptionWidthContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 20%;
}

.active {
  text-decoration: underline;
}

.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.phoneGif {
  height: 400px;
  border-radius: 10px;
}

.computerGif {
  width: 400px;
  height: auto;
  border-radius: 5px;
}

.aboutPage {
  height: 100vh;
  background-color: var(--pink);
  border-top: solid 25px var(--mediumGray);
  display: flex;
  flex-direction: row;
}

.aboutPageBefore {
  height: 100vh;
  background-color: white;
  visibility: hidden;
}

.aboutImgContainer {
  border: solid 25px var(--darkGray);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.aboutImgContainer:hover {
  border: solid 25px var(--darkGray);
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}

.aboutHeader {
  font-size: 120px;
  font-weight: 800;
  margin: 0px;
  color: var(--darkGray);
}

.bioContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10%;
}

.bioText {
  font-size: 14px;
  font-weight: 700;
  width: 70%;
  text-align: left;
  line-height: 18px;
  color: var(--darkGray);
}

.bioTextLink {
  font-size: 14px;
  font-weight: 700;
  width: 70%;
  text-align: left;
  line-height: 18px;
  color: var(--darkGray);
  text-decoration: underline;
}

.bioTextLink:hover {
  font-size: 14px;
  font-weight: 700;
  width: 70%;
  text-align: left;
  line-height: 18px;
  color: var(--lightGray);
  text-decoration: underline;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.iconLink {
  font-size: 40px;
  color: var(--darkGray);
  margin-right: 10px;
}

.iconLink:hover {
  font-size: 40px;
  cursor: pointer;
  transform: scale(1.2);
}

.projectButtonContainerActive {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  align-items: flex-start;
}

@media (max-width: 500px) {
  .introText {
    font-size: 15vw;
    font-weight: 800;
    margin: 0px;
    color: var(--darkGray);
  }

  .introContainer {
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--pink);
    padding: 10px;
  }

  .specialtiesContainer {
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    margin-top: 12%;
  }

  .specialtiesHeaderText {
    font-size: 16px;
    color: var(--mediumGray);
    font-weight: 800;
  }

  .specialtiesText {
    font-size: 16px;
    color: var(--darkGray);
    font-weight: 800;
    margin: 0px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: solid;
    border-bottom-color: var(--lightGray);
    width: 250px;
    text-align: left;
  }

  .graySquare {
    width: 50%;
    background-color: var(--mediumGray);
    height: 160%;
    position: absolute;
    top: 0px;
    z-index: -1;
    right: 0px;
    border: none;
    visibility: hidden;
  }

  .pinkCircle {
    width: 120%;
    height: 35vw;
    border-radius: 50%;
    background-color: var(--pink);
    position: absolute;
    visibility: hidden;
  }

  .labelText {
    font-size: 16px;
    font-weight: 800;
    margin: 0px;
    color: var(--darkGray);
    padding: 5px;
  }

  .projectsHeader {
    font-size: 15vw;
    font-weight: 800;
    margin: 0px;
    color: var(--darkGray);
  }

  .projectLabel {
    font-size: 12px;
    font-weight: 700;
    margin: 0px;
    margin-top: 10px;
    color: var(--darkGray);
    width: 50%;
  }

  .activeProjectContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .projectButtonActive {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
  }

  .projectButtonContainerActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .verticalButtonContainer {
    display: flex;
    flex-direction: column;
  }

  .descriptionHeaderContainer {
    height: 0;
    visibility: hidden;
  }

  .descriptionWebsite {
    visibility: hidden;
  }

  .descriptionText {
    visibility: collapse;
    height: 0;
  }

  .stackText {
    font-weight: 700;
    font-size: 14px;
    color: var(--mediumGray);
    text-align: center;
    margin: 0;
    padding-top: 10px;
    text-transform: uppercase;
    border-bottom: solid 2px var(--lightGray);
    padding-bottom: 20px;
    width: 100%;
  }

  .stackContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .stackListText {
    font-weight: 700;
    font-size: 12px;
    color: var(--darkGray);
    text-align: center;
    margin: 0;
    padding-top: 10px;
    text-transform: uppercase;
    border-bottom: solid 2px var(--lightGray);
    padding-bottom: 5px;
    width: 100%;
  }

  .active {
    text-decoration: underline;
  }

  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .descriptionWidthContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .computerGif {
    width: 90%;
    height: auto;
  }

  .projectsPage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .aboutPage {
    background-color: var(--pink);
    border-top: solid 25px var(--mediumGray);
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    height: fit-content;
    padding-bottom: 50px;
  }

  .aboutPageBefore {
    background-color: white;
    visibility: hidden;
  }

  .aboutImgContainer {
    border: solid 25px var(--darkGray);
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .aboutImgContainer:hover {
    border: solid 25px var(--darkGray);
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }

  .aboutHeader {
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 10px;
    color: var(--darkGray);
    padding-top: 50px;
  }

  .bioContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }

  .bioText {
    font-size: 10px;
    font-weight: 700;
    width: 70%;
    text-align: center;
    line-height: 18px;
    color: var(--darkGray);
  }

  .bioTextLink {
    font-size: 10px;
    font-weight: 700;
    width: 70%;
    text-align: left;
    line-height: 18px;
    color: var(--darkGray);
    text-decoration: underline;
  }

  .bioTextLink:hover {
    font-size: 14px;
    font-weight: 700;
    width: 70%;
    text-align: left;
    line-height: 18px;
    color: var(--lightGray);
    text-decoration: underline;
  }

  .iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .iconLink {
    font-size: 40px;
    color: var(--darkGray);
    margin-right: 10px;
  }

  .iconLink:hover {
    font-size: 40px;
    cursor: pointer;
    transform: scale(1.2);
  }

  .aboutImgContainer {
    height: auto;
    object-fit: cover;
  }
}
